

import InspectionList from "@/components/inspections/InspectionList"

export default {
  name: "Inspections",
  components: {
    InspectionList,
  },
}
